import {create} from 'zustand'
import { devtools, persist } from 'zustand/middleware'
let store = (set) => ({
    isLogin: 0,
    userPermission: "",
    userType: '',
    cartCount: 0,
    cartProductId: [],
    userId: '',
    userDetails: [],
    isMember: 0,
    afterLogin: (userPermission, userType, userId, userDetails, isMember) => set((state) => ({
        isLogin: 1,
        userPermission: userPermission,
        userType: userType,
        userId: userId,
        userDetails: userDetails,
        isMember:isMember
    })),
    afterLogout: () => set((state) => ({
        isLogin: 0,
        userPermission: '',
        userType: '',
        userId: '',
        userDetails: ''
    })),
    addToCart: (pID) => set((state) => ({
        cartCount: state.cartCount + 1,
        cartProductId: [
            { producId: pID },
            ...state.cartProductId,
        ],
    })),
})
store = devtools(store)
store = persist(store, { name: 'useData' })
const useStore = create(store)
export default useStore