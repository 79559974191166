import React, { useState, useEffect } from 'react';
import { Link, useNavigate, useParams } from 'react-router-dom';
import Button from 'react-bootstrap/Button';
import Table from 'react-bootstrap/Table';
import Modal from 'react-bootstrap/Modal';
import Tab from 'react-bootstrap/Tab';
import Tabs from 'react-bootstrap/Tabs';
import Spinner from 'react-bootstrap/Spinner';
import axios from 'axios';
import Swal from 'sweetalert2';
import DataTable from 'react-data-table-component';
import { Formik, Field, Form } from 'formik';
import * as Yup from 'yup';
import CustomErrorMsg from "./CustomErrorMsg";
import Badge from 'react-bootstrap/Badge';
import * as Moment from 'moment';
import { extendMoment } from 'moment-range';

import cartIcon from '../../assests/frontend/assets/images/shopping-cart.png';
import infoIcon from '../../assests/frontend/assets/images/info.png';
import copyIcon from '../../assests/frontend/assets/images/copy.png';
import eyeIcon from '../../assests/frontend/assets/images/eye.png';
import loading from '../../assests/frontend/assets/images/loading.png';
import money from '../../assests/frontend/assets/images/notes.png';
import useStore from '../../store';
import Copyright from '../Copyright';
import {BASE_URL} from '../Config';
import Sidebar2 from '../../layouts/frontend/Sidebar2';
/*formik validation required libraries*/



function MyAccountInfo2() {
    const moment = extendMoment(Moment);
    let navigate = useNavigate();
    const [isNavVisible, setNavVisibility] = useState(false);
    const afterLogout = useStore((state) => state.afterLogout);
    let userId = useStore((state) => state.userId);
    let userEmail = useStore((state) => state.userDetails.email);
    const [show, setShow] = useState(false);
    const acHandleClose = () => setShow(false);
    const acHandleShow = () => setShow(true);
    const params2 = useParams();

    const [tRshow, settRShow] = useState(false);
    const totalReferedClose = () => settRShow(false);
    const totalReferedShow = () => settRShow(true);


    const [updateBilling, setUpdateBilling] = useState(false);
    const updateBillingClose = () => setUpdateBilling(false);
    const updateBillingShow = () => setUpdateBilling(true);


    const [orderHistory, setOrderHistory] = useState(false);
    const orderHistoryClose = () => setOrderHistory(false);
    const orderHistoryShow = () => setOrderHistory(true);



    const [orderHistoryDetails, setOrderHistoryDetails] = useState(false);
    const orderHistoryDetailsClose = () => setOrderHistoryDetails(false);
    const orderHistorDetailsyShow = () => setOrderHistoryDetails(true);

    const [Details, setDetails] = useState([]);
    const [First, setFirst] = useState("");
    const [Last, setLast] = useState("");
    const [List, setList] = useState([]);
    const [pending, setPending] = useState(true);
    const [pendingOrderData, setPendingOrderData] = useState(true);
    const [search, setSearch] = useState([]);
    const [searchData, setsearchData] = useState("");
    const [isCopied, setIsCopied] = useState(false);
    const [orderList, setOrderList] = useState([]);
    const [searchOrder, setSearchOrder] = useState([]);
    const [SubcriptionList, setSubcriptionList] = useState([]);
    const [searchSubcription, setSearchSubcription] = useState([]);
    const [ActiveSubcription, setActiveSubcription] = useState([]);
    const [states, setStates] = useState([]);

    /*useEffect(()=>{
        handleCountryChange2('239');
    },[params2.slug]);*/

    const handleCountryChange2 = (country_id) => {
        axios.get(`api/get-state/${country_id}`).then(res => {
            if(res.data.status === true) {
                setStates(res.data.data);
            }
        })
    };

    const handleCountryChange = (event) => {
        const country_id = event.target.value;
        console.log(country_id);
        axios.get(`api/get-state/${country_id}`).then(res => {
            if(res.data.status === true) {
                setStates(res.data.data);
            }
        })
    };

    const [productsList, setProductList] = useState([]);
    const [addClass, setAddClass] = useState("");
    const afterLogin = useStore((state) => state.afterLogin);

    let changePasswordSubmit = (data) => {

        axios.post(`api/change_password`, { ...data, email: Details.email }).then(res => {
            if (res.data.response_code === '100') {
                Swal.fire({
                    title: 'Success',
                    html: "Your Password Updated Successfully",
                    icon: 'success',
                    showConfirmButton: true,
                    // timer: 100
                }).then((result) => {
                    if (result.isConfirmed) {
                        if(localStorage.getItem('loggedInAs') === 'admin'){
                            //console.log(localStorage.getItem('loggedInAs'));
                        } else {
                            localStorage.removeItem('authToken');
                            localStorage.removeItem('userId');
                            localStorage.removeItem('permission');
                            afterLogout();
                        }
                        navigate('/login');
                    }
                });
            }
            else {
                Swal.fire({
                    title: 'Please see the below response',
                    html: res.data.response_message,
                    icon: 'error',
                    showConfirmButton: false,
                    showCancelButton: true,
                    // timer: 4000
                });
            }
        }).catch(err => {
            Swal.fire({
                title: 'error',
                html: err.response.data.message,
                icon: 'error',
                showConfirmButton: false,
                showCancelButton: true,
            })
        });

    }

    const [email, setEmail] = useState("");
    const [orderId, setOrderId] = useState("");
    const [productID, setProductID] = useState("");
    const handelInput = (e) => {
        e.persist();
        if (e.target.name == "membership") {
            const index = e.target.selectedIndex;
            const el = e.target.childNodes[index]
            const email = el.getAttribute('data-email');
            const orderId = el.getAttribute('data-orderId');
            setProductID(e.target.value);
            setOrderId(orderId);
            setEmail(email);
        }

    }

    let user_id = localStorage.getItem('userId') ? localStorage.getItem('userId') : userId;
    let getUserAccountInfo = () => {
        axios.get(`api/getUserAccountInfo/${user_id}`).then(res => {
            if (res.data.status === true) {
                setDetails(res.data.data);
                if(res.data.data.country === "US"){
                    handleCountryChange2("239");
                }else {
                    handleCountryChange2("41");
                }
                getUserSubscription(res.data.data.email);
            }
        }).catch(err => {
            Swal.fire({
                title: 'Warning',
                html: err.response.data.message,
                icon: 'warning'
            })
        })
    }

    let getListOrders = (key) => {
        //let userEmail = localStorage.getItem('userEmal');
        switch (key) {
            case 'OrderHistory':
                {
                    setPendingOrderData(true);
                    setOrderList([]);
                    axios.post(`api/order_history`, { email: userEmail }).then(res => {
                        if (res.data.status === true) {
                            setPending(false);
                            setOrderList(res.data.data);
                            setSearchOrder(res.data.data);

                            //console.log(searchOrder);
                            


                        }
                    }).catch(err => {
                        //console.log(err);
                    })
                }
                break;
            case 'Referral':
                {
                    setPending(true);
                    setList([]);
                    axios.get(`api/getReferredUserList/${user_id}`).then(res => {
                        //console.log(res.data.data);
                        setSearch(res.data.data);
                        setList(res.data.data);
                        setPending(false);
                    });
                   
                }
                break;
            case 'Subscriptions':
                {
                    setloaderShow(true);
                    getUserSubscription(userEmail);
                }
                break;
            case 'Membership':
                {
                    setloaderShow(false);
                    getMembershipList();
                }
                break;
            default:
                break;
        }
    }

    let getUserSubscription = (userEmail) => {
            setPending(true);
            setSubcriptionList([]);
        axios.post(`api/get_user_subcription`, { email: userEmail }).then(res => {
            if (res.data.status === true) {
                setActiveSubcription(res.data.activeMembership ? res.data.activeMembership : '');
                setSubcriptionList(res.data ? res.data.data : '');
                setSearchSubcription(res.data ? res.data.data : '');
                setProductID(res.data.activeMembership ? res.data.activeMembership[0].product_id : '');
                setPending(false);
            }
        }).catch(err => {
            
            Swal.fire({
                title: 'Warning',
                html: err.response.data.message,
                icon: 'warning'
            })
        })
    }

    let displayProductList = (product) => {
        setProductList(product);
        setShowModal(true);
    }

    const columns = [
        {
            name: "NAME",
            selector: row => row.name
        },
        {
            name: "EMAIL",
            selector: row => row.email
        },
        {
            name: "REGISTERED AT",
            cell: row => (
                <span>{row.created_at.split("T")[0]}</span>
            )
        },
    ]

    const columns1 = [
        {
            id: 'oderId',
            name: "ORDER #",
            selector: row => row.order_id
        },
        {
            id: 'orderDate',
            name: "ORDER DATE",
            selector: row => moment(row.acquisition_date).format('M/D/Y')
        },
        {
            name: "Product",
            selector: row => row.products[0].name
        },
        {
            name: "Order Total",
            selector: row => '$'+row.order_total
        },
        {
            name: "STATUS",
            selector: row => {
                switch (row.order_status) {
                    case '2':
                        return "Approved";
                        break;
                    case '6':
                        return "Void/Refunded";
                        break;
                    case '7':
                        return "Declined";
                        break;
                    case '8':
                        return "Shipped";
                        break;
                    case '11':
                        return "Pending";
                        break;
                    default:
                        break;
                }
            }
        },
        {
            name: "Action",
            cell: row => (
                <>
                    <Button className='brn_norm' variant="primary" onClick={() => { displayProductList(row.products) }}>
                        View Orders
                    </Button>
                </>
            )
        }
    ]

    //membership colom
    const columns2 = [
        {
            id: 'oderId',
            name: "ORDER #",
            selector: row => row.order_id
        },
        {
            id: 'orderDate',
            name: "START DATE",
            selector: row => row.start_date
        },
        {
            name: "NEXT REBILL DATE",
            cell: row => (
                <>
                    <span>{row.status == 'IN ACTIVE' ? "--" : row.recurring_date}</span>
                </>
            )
        },
        {
            name: "PRODUCT NAME",
            selector: row => row.description
        },
        {
            name: "	AMOUNT",
            selector: row => row.ammount
        },

        {
            name: "STATUS",
            cell: row => (
                <>
                    <Badge bg={row.status == 'IN ACTIVE' ? "danger" : "success"}>{row.status}</Badge>
                </>
            )
        },
        {
            name: "Action",
            cell: row => (
                <>
                    {row.status == 'IN ACTIVE' ? <label>--</label> : <Button disabled={row.status == 'IN ACTIVE' ? true : false} className='brn_norm' variant="primary" onClick={() => { cancelSubcription(row.order_id, row.product_id, row.status) }}>
                        {row.status == 'IN ACTIVE' ? "---" : "Cancel"}
                    </Button>}

                </>
            )
        },
    ]
    // const afterLogout = useStore((state) => state.afterLogout)
    function cancelSubcription(order_id, product_id, status) {
        var cTitle = 'cancel';
        if (status == 'IN ACTIVE') {
            cTitle = 'start';
        }
        Swal.fire({
            title: 'Are you sure you want to ' + cTitle + ' this subscription?',
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Yes, ' + cTitle + ' it!',
            showCloseButton: true
        }).then((result) => {
            if (result.isConfirmed) {
                var combineData = order_id + '_' + product_id + '_' + status;
                axios.post(`api/cancel_subscription/${combineData}`).then(res => {
                    var sMsg = 'Cancelled';
                    if (status == 'IN ACTIVE') {
                        sMsg = 'Started';
                    }
                    Swal.fire({
                        title: sMsg,
                        html: res.data.message,
                        icon: 'success',
                        showConfirmButton: false,
                        timer: 1000
                    })
                    localStorage.removeItem('authToken');
                    localStorage.removeItem('bearer');
                    localStorage.removeItem('permission');
                    localStorage.removeItem('userEmal');
                    localStorage.removeItem('userId');
                    localStorage.removeItem('order_id');
                    afterLogout()
                    window.location.href = '/login'
                })
            }
        })
    }
    const [loadershow, setloaderShow] = useState(false);
    const updateMembership = (e) => {
        e.preventDefault();
        if (email == '' || productID == '' || orderId == '') {
            Swal.fire({
                title: 'Warning',
                html: "Please select a membership",
                icon: 'warning'
            })
            return false;
        }
        Swal.fire({
            title: 'Are you sure you want to change this subscription?',
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Yes, Change it!',
            showCloseButton: true
        }).then((result) => {
            if (result.isConfirmed) {
                // console.log(Details)
                setloaderShow(true);
                var combineData = email + '_' + orderId + '_' + productID;

                // date difference calculation
                const CurrentDate = moment().format('M/D/Y');
                const RecurringDate = moment(ActiveSubcription[0].recurring_date).format('M/D/Y');
                const range = moment.range(CurrentDate, RecurringDate);
                const dateDiff = range.diff('days');

                let NextRecurringDate = '';
                let OrderId = ActiveSubcription[0].order_id;
                if (parseInt(dateDiff) > 30) {
                    NextRecurringDate = moment(RecurringDate).add(30, 'days').format('M/D/Y');
                }
                axios.post('api/updateMembership', { email: Details.email, product_id: productID, order_id: OrderId, recurring_date: NextRecurringDate }).then(res => {
                    Swal.fire({
                        title: 'Membership Changed',
                        html: res.data.resp_msg,
                        icon: 'success',
                        showConfirmButton: false,
                        timer: 1000
                    })
                    getUserAccountInfo();
                    setTimeout(function(){
                        setloaderShow(false);
                    },3000);
                    
                })
            }
        })
    }
    // Modal 
    // Order history view all modal state
    const [showModal, setShowModal] = useState(false);
    const handleClose = () => setShowModal(false);
    const handleShow = () => setShowModal(true);

    // This is the function we wrote earlier
    async function copyTextToClipboard(text) {
        if ('clipboard' in navigator) {
            return await navigator.clipboard.writeText(text);
        } else {
            return document.execCommand('copy', true, text);
        }
    }

    const handleCopyClick = (e) => {
        e.preventDefault();
        copyTextToClipboard(BASE_URL+"create-membership?ref="+Details.refferral_code)
            .then(() => {
                setIsCopied(true);
                setTimeout(() => {
                    setIsCopied(false);
                }, 1500);
            })
            .catch((err) => {
                // console.log(err);
            });
    }

    const handleCopyCodeClick = (e) => {
        e.preventDefault();
        copyTextToClipboard(Details.refferral_code)
            .then(() => {
                // If successful, update the isCopied state value
                setIsCopied(true);
                setTimeout(() => {
                    setIsCopied(false);
                }, 1500);
            })
            .catch((err) => {
                // console.log(err);
            });
    }

    useEffect(() => {
        window.scrollTo(0, 0);
        getUserAccountInfo();
    }, []);

    function updateOrderHistoryData(search_value){
        const res = orderList.filter(orderData => {
            return orderData.order_id.match(search_value);
            //return orderData.products[0].name.toLowerCase().match(searchOrderData);
        })
        setSearchOrder(res);
    }

    function updateReferralHistoryData(search_value){
        
        const result = List.filter(referredName => {
            return referredName.name.toLowerCase().match(search_value.toLowerCase());
        })
        setSearch(result);
    }

    function updateSubscriptionHistoryData(search_value){
       
        const result = SubcriptionList.filter(order => {
            return order.order_id.match(search_value);
        })
        
        setSearchSubcription(result);
    }

    useEffect(()=>{
      
        if(searchOrder.length)
        {
            setPendingOrderData(false);
        }

    },[searchOrder]);

    useEffect(()=>{
      
        if(search.length)
        {
           
            setPending(false);
        }

    },[search]);

    useEffect(()=>{
      
        if(searchSubcription.length)
        {
           
            setPending(false);
        }

    },[searchSubcription]);

    const updateSubmit = (formdata) => {
        axios.post(`api/update-billing-details`, formdata).then(res => {
            // console.log('msg',res.data.data.user_id);
            if (res.data.status == true) {
                axios.get(`api/check_user_membership/${userEmail}`).then(result => {
                    let is_membership = 0;
                    if(parseInt(result.data) === 1){
                        is_membership = 1;
                    } else {
                        is_membership = 0;
                    }
                    afterLogin(res.data.data.account_permission,res.data.data.user_type,res.data.data.user_id,res.data.data,is_membership);
                    Swal.fire({
                        title: 'Success',
                        html: "Data updated",
                        icon: 'success',
                        showConfirmButton: true
                    });
                })
            }
        }).catch(err => {
            //console.log(err.response);
            Swal.fire({
                title: 'Warning',
                html: "Something went wrong! Please try again",
                icon: 'warning'
            })
        });
    }

    const [membershipList, setMembershipList] = useState([]);
    function getMembershipList() {
        axios.get(`api/listMappedMembershipProduct`).then(res => {
            //console.log(res.data);
            setMembershipList(res.data.filter((item)=>{if(item.product_id!=41){return item}}));
        })
    }
    // useEffect(() => { getProductList(); }, []);
    useEffect(() => {
        const timeout = setTimeout(() => {
            getMembershipList();
        }, 1000);
        return () => clearTimeout(timeout);
    }, []);


    const toggleNavVisibility = () => {
        setNavVisibility(!isNavVisible);
    };

    return (
        <>

            {/* My Account Page Begin  */}
            <div className='myAccountInfo-section layoutSidenav_content'>

                <div className='container-fluid'>
                
                    {/* Row Begins  */}
                    <div className="row newShopList_row">
                        <Sidebar2 isNavVisible={isNavVisible} />
                        {/* Left Col Begin  */}
                        <div className="col-md-8 col_right">
                            <div className='accInfo-blocks'>
                                <button className={`btn nwSideNav_btn ${isNavVisible ? 'show' : ''}`} onClick={toggleNavVisibility}><span></span><span></span><span></span></button>
                                <div className='myAccTab-wrapper'>
                                    <Tabs
                                        onSelect={(k) => getListOrders(k)}
                                        defaultActiveKey="Account"
                                        id="uncontrolled-tab-example"
                                        className="myAccTab"
                                    >
                                        <Tab eventKey="Account" title="Account Details">
                                            <div className='infoCard-body'>
                                                <Formik
                                                    enableReinitialize={true}
                                                    initialValues={{ 
                                                        firstname: Details.firstname, 
                                                        lastname: Details.lastname, 
                                                        phone: Details.phone, 
                                                        email: Details.email, 
                                                        address: Details.address, 
                                                        city: Details.city, 
                                                        state: Details.state, 
                                                        zip: Details.zip, 
                                                        country: Details.country === 'US' ? '239' : '41'
                                                    }}
                                                    validationSchema={Yup.object({
                                                        firstname: Yup.string().required('First name is required'),
                                                        lastname: Yup.string().required('Last name is required'),
                                                        phone: Yup.number().integer().required('Phone no is required'),
                                                        country: Yup.string().required('Please select country'),
                                                        state: Yup.string().required('Please select state'),
                                                        zip: Yup.string().when('country', {
                                                            is: '239',
                                                            then: Yup.string()
                                                                .required('Zip code is required')
                                                                .matches(/^\d{5}$/, 'Zip code must be exactly 5 digits number'),
                                                            otherwise: Yup.string().when('country', {
                                                                is: '41',
                                                                then: Yup.string()
                                                                .required('Zip code is required')
                                                                .matches(/^[A-Za-z]\d[A-Za-z] ?\d[A-Za-z]\d$/, 'Zip code must contain only letters and numbers')
                                                                .min(6, 'Zip code should contain at least 6 characters')
                                                                .max(6, 'Zip code should contain at most 6 characters'),
                                                            }),
                                                        }),
                                                    })}

                                                    onSubmit={(values, { setSubmitting }) => {
                                                        setSubmitting(true);
                                                        // alert(JSON.stringify(values, null, 2));
                                                        if(values.shipping_address !==''){
                                                            values.billingSameAsShipping = 'NO';
                                                        } else {
                                                            values.billingSameAsShipping = 'YES';

                                                        }
                                                        let modifiedCountry;
                                                        if (values.country === '239') {
                                                            modifiedCountry = 'US';
                                                        } else {
                                                            modifiedCountry = 'CA';
                                                        }
                                                        updateSubmit(values);

                                                    }}
                                                >
                                                    {(formProps) => (
                                                    <Form className=''>
                                                        <p className="titleBlock">Account Information</p>
                                                        <div className='formDiv-cus'>
                                                            <div className='row formDiv-row'>
                                                                <div className='col-md-6 formDiv-col'>
                                                                    <label>First Name</label>
                                                                </div>
                                                                <div className='col-md-6 formDiv-col'>
                                                                    <Field className='form-control' name="firstname" type="text" />
                                                                    <CustomErrorMsg name="firstname" />
                                                                </div>
                                                            </div>
                                                            <div className='row formDiv-row'>
                                                                <div className='col-md-6 formDiv-col'>
                                                                    <label>Last Name</label>
                                                                </div>
                                                                <div className='col-md-6 formDiv-col'>
                                                                    <Field className='form-control' name="lastname" type="text" />
                                                                    <CustomErrorMsg name="lastname" />
                                                                </div>
                                                            </div>
                                                            <div className='row formDiv-row'>
                                                                <div className='col-md-6 formDiv-col'>
                                                                    <label>Phone</label>
                                                                </div>
                                                                <div className='col-md-6 formDiv-col'>
                                                                    <Field className='form-control' type="tel" name="phone" maxLength={10} />
                                                                    <CustomErrorMsg name="phone" />
                                                                </div>
                                                            </div>
                                                            <div className='row formDiv-row'>
                                                                <div className='col-md-6 formDiv-col'>
                                                                    <label>Email*</label>
                                                                </div>
                                                                <div className='col-md-6 formDiv-col'>
                                                                    <Field className='form-control' type="mail" name="email" readOnly />
                                                                    <CustomErrorMsg name="email" />
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <p className="titleBlock">Billing Address</p>
                                                        <div className='formDiv-cus'>
                                                            <div className='row formDiv-row'>
                                                                <div className='col-md-6 formDiv-col'>
                                                                    <label>Address</label>
                                                                </div>
                                                                <div className='col-md-6 formDiv-col'>
                                                                    <Field className='form-control' type="text" name="address" />
                                                                    <CustomErrorMsg name="address" />
                                                                </div>
                                                            </div>
                                                            <div className='row formDiv-row'>
                                                                <div className='col-md-6 formDiv-col'>
                                                                    <label>City</label>
                                                                </div>
                                                                <div className='col-md-6 formDiv-col'>
                                                                    <Field className='form-control' type="text" name="city" />
                                                                    <CustomErrorMsg name="city" />
                                                                </div>
                                                            </div>
                                                            <div className='row formDiv-row'>
                                                                <div className='col-md-6 formDiv-col'>
                                                                    <label>Country</label>
                                                                </div>
                                                                <div className='col-md-6 formDiv-col'>
                                                                    <Field
                                                                        name="country"
                                                                        as="select"
                                                                        className="form-control input-text"
                                                                        onChange={(event) => {
                                                                            //formProps.handleChange(event);
                                                                            formProps.setFieldValue('country', event.target.value);
                                                                            formProps.setFieldValue('state', '');
                                                                            handleCountryChange(event);
                                                                        }}
                                                                    >
                                                                        <option value="239">United States</option>
                                                                        <option value="41" >Canada</option>
                                                                    </Field>
                                                                    <CustomErrorMsg name="country" />
                                                                </div>
                                                            </div>
                                                            <div className='row formDiv-row'>
                                                                <div className='col-md-6 formDiv-col'>
                                                                    <label>State</label>
                                                                </div>
                                                                <div className='col-md-6 formDiv-col'>
                                                                    <Field
                                                                        name="state"
                                                                        as="select"
                                                                        className="form-control input-text"
                                                                    >
                                                                        <option value="" >Select state</option>
                                                                        {states.map((state) => (
                                                                            <option 
                                                                                key={state.id} 
                                                                                value={state.abbreviation}
                                                                                selected={state.abbreviation === Details.state}>
                                                                                {state.state}
                                                                            </option>
                                                                        ))}
                                                                    </Field>
                                                                    <CustomErrorMsg name="state" />
                                                                </div>
                                                            </div>
                                                            <div className='row formDiv-row'>
                                                                <div className='col-md-6 formDiv-col'>
                                                                    <label>Zip Code</label>
                                                                </div>
                                                                <div className='col-md-6 formDiv-col'>
                                                                    <Field maxLength="6" className='form-control' type="text" name="zip" />
                                                                    <CustomErrorMsg name="zip" />
                                                                </div>
                                                            </div>
                                                            
                                                        </div>
                                                    
                                                        <div className='actionBtn text-right'>
                                                            <button type="submit" className="btn btn_update_mid" >Update</button>
                                                            <button className='btn btn_cancle_mid'>Cancel</button>
                                                        </div>
                                                    </Form>
                                                    )}
                                                </Formik>
                                            </div>
                                        </Tab>
                                        <Tab eventKey="Referral" title="Referral Info">
                                            <div className='infoCard-body'>
                                                <Formik
                                                    enableReinitialize={true}
                                                    initialValues={{ refferral_code: Details.refferral_code, referral_link: BASE_URL+"create-membership?ref="+Details.refferral_code }}
                                                >
                                                    <Form className=''>
                                                        <div className='formDiv-cus'>
                                                            <div className='row formDiv-row'>
                                                                <div className='col-md-4 col-lg-3 formDiv-col'>
                                                                    <label>Referral Code</label>
                                                                </div>
                                                                <div className='col-md-8 col-lg-8 formDiv-col'>
                                                                    <div className='formDiv-abs-inner readOnly-field'>
                                                                        <Field className='form-control' type="text" name="refferral_code" readOnly={true} />
                                                                        <button onClick={handleCopyCodeClick} className='copyTxt'><img src={copyIcon} alt="" /><span className='copyTxtTooltip'>{isCopied ? 'Copied!' : 'Copy'}</span></button>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div className='row formDiv-row'>
                                                                <div className='col-md-4 col-lg-3 formDiv-col'>
                                                                    <label>Referral Link</label>
                                                                </div>
                                                                <div className='col-md-8 col-lg-8  formDiv-col'>
                                                                    <div className='formDiv-abs-inner readOnly-field'>
                                                                        <Field className='form-control' type="text" name="referral_link" readOnly={true} />
                                                                        <button onClick={handleCopyClick} className='copyTxt'><img src={copyIcon} alt="" /><span className='copyTxtTooltip'>{isCopied ? 'Copied!' : 'Copy'}</span></button>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div className='row formDiv-row'>
                                                                <div className='col-md-12 formDiv-col mb-3'>
                                                                    {/* <label>Referred Details</label> */}
                                                                    <p className="titleBlock">Referred Details</p>
                                                                </div>
                                                                <div className='col-md-12 formDiv-col'>
                                                                    <div className='orderHistory-table dataTable-wapper'>
                                                                        <div className='table-responsive'>
                                                                            <DataTable
                                                                                noDataComponent="No data found" //or your component
                                                                                columns={columns}
                                                                                data={search}
                                                                                progressPending={pending}
                                                                                pagination
                                                                                fixedHeader
                                                                                subHeader
                                                                                subHeaderComponent={
                                                                                    <input type="text" className={(List.length)? 'form-control serachInput' : 'd-none'} placeholder="Search By Name"  onChange={(e) => { updateReferralHistoryData(e.target.value) }} />
                                                                                }
                                                                                subHeaderAlign="right"
                                                                            />

                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </Form>
                                                </Formik>
                                            </div>
                                        </Tab>

                                        <Tab eventKey="Subscriptions" title="Subscriptions">
                                            <div className='infoCard-body'>
                                                <div className='orderHistory-table'>
                                                    <div className='table-responsive'>
                                                        {/* <Table striped  hover>
                                                            <thead>
                                                                <tr>
                                                                    <th>START DATE</th>
                                                                    <th>DESCRIPTION</th>
                                                                    <th>AMOUNT</th>
                                                                    <th>ACTION</th>
                                                                    <th>STATUS</th>
                                                                </tr>
                                                            </thead>
                                                            <tbody>
                                                                <tr>
                                                                    <td>Sep 15, 2022</td>
                                                                    <td>Sapphire Membership</td>
                                                                    <td>$12.77 every 30 days</td>
                                                                    <td><button className="btn btn_cancle_mid">Cancel</button></td>
                                                                    <td></td>
                                                                </tr>
                                                            </tbody>
                                                        </Table> */}
                                                        <DataTable
                                                            noDataComponent="No data found" //or your component
                                                            columns={columns2}
                                                            data={searchSubcription}
                                                            progressPending={pending}
                                                            pagination
                                                            fixedHeader
                                                            subHeader
                                                            subHeaderComponent={
                                                                <input type="text" className={(SubcriptionList.length)? 'form-control serachInput ':'d-none'}  placeholder="Search By Order Id"  onChange={(e) => { updateSubscriptionHistoryData(e.target.value) }} />
                                                            }
                                                            subHeaderAlign="right"
                                                            defaultSortFieldId="oderId"
                                                            defaultSortAsc={false}
                                                        />
                                                    </div>
                                                </div>
                                            </div>
                                        </Tab>
                                        <Tab eventKey="Membership" title="Membership">
                                            <div className='infoCard-body'>
                                                {ActiveSubcription ?
                                                <>
                                                    <div className='row'>
                                                        <h3 style={{ textAlign: 'center', padding: '50px' }} className={loadershow ? '' : 'd-none'}>Please Wait...</h3>
                                                        <div className={loadershow ? 'col-lg-9 d-none' : 'col-lg-9'}>

                                                            <form className=''>
                                                                <div className='formDiv-cus'>
                                                                    <div className='row formDiv-row align-items-center'>
                                                                        <div className='col-md-6 col-lg-4 formDiv-col mb-2 mb-md-0'>
                                                                            <label>Membership:</label>
                                                                        </div>
                                                                        <div className='col-md-6 col-lg-8 formDiv-col'>
                                                                            <label className="text-info">{ActiveSubcription[0] ? ActiveSubcription[0].description : ''}</label>
                                                                        </div>
                                                                    </div>
                                                                    <div className='row formDiv-row align-items-start'>
                                                                        <div className='col-md-6 col-lg-4 formDiv-col mt-1 mb-2 mb-md-0'>
                                                                            <label>Change Membership:</label>
                                                                        </div>
                                                                        <div className='col-md-6 col-lg-8 formDiv-col'>
                                                                            <select type="" name="membership" id="membership" className="form-control" onChange={handelInput}>
                                                                                <option value="">Select Membership</option>

                                                                                {ActiveSubcription[0] ? membershipList.map(membershipList => {

                                                                                    if (parseInt(membershipList.crm_product_id) === parseInt(ActiveSubcription[0].product_id)) {
                                                                                        return null;
                                                                                    }
                                                                                    var monthText = membershipList.name + " - $" + membershipList.price + ' ' + membershipList.billing_model_name.split(")")[1]
                                                                                
                                                                                    return (
                                                                                        (membershipList.product_id != 177) ? (
                                                                                        <option value={membershipList.product_id} key={membershipList.id} data-orderId={ActiveSubcription[0] ? ActiveSubcription[0].order_id : ''} data-email={ActiveSubcription[0] ? ActiveSubcription[0].email : ''}  >
                                                                                            {monthText}
                                                                                        </option>
                                                                                        ) : ''
                                                                                        
                                                                                    );
                                                                                }) : ''}
                                                                            </select>

                                                                        </div>
                                                                    </div>


                                                                </div>
                                                            </form>
                                                        </div>
                                                        <div className={loadershow ? 'col-lg-3 d-none' : 'col-lg-3'}>
                                                            <div className='actionBtn2'>
                                                                <button onClick={updateMembership} className="btn btn_update_mid">Change Membership</button>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className='membershipDetails-outter'>
                                                        {/* Sapphire Membership Level Benefits: */}
                                                        {(productID === "141") || (productID === "41") || (productID === "61") || (productID === "81") ?
                                                            <div className="membershipDetails-tab">
                                                                <div className='mD-header'>
                                                                    <p> Sapphire Membership Level Benefits:</p>
                                                                </div>
                                                                <div className='mD-body'>
                                                                    <ul className="referralTxt-lists">
                                                                        <li>Access to premium plus</li>
                                                                        <li>All Gold Membership Level Benefits.</li>
                                                                        <li>Purchase unlimited supplements at $15 per bottle.</li>
                                                                        <li>Purchase unlimited skincare products at $20 per item</li>
                                                                        {/* <li>Purchase unlimited powder products at $25 per item</li> */}
                                                                        <li>Bonus offer: you will receive 1 free month of membership when 1 Successful Referral sign up using your referral code.</li>
                                                                        <li>Bonus offer: for every 5 bottles purchased, get 1 free.</li>
                                                                        <li>Bonus ebooks</li>
                                                                        <li>The Sapphire Membership will be automatically charged $19.83 every month thereafter for a period of 30 days from <br /> date of purchase. You can cancel anytime by calling <a href="tel:(480)573-0829">(480)573-0829</a> or by emailing<br /> <a href="mailto:support@wholesalehealthclub.com">support@wholesalehealthclub.com</a>.</li>
                                                                    </ul>
                                                                </div>
                                                            </div>
                                                            : ""}
                                                        {/* Platinum Membership Level Benefits: */}
                                                        {(productID === "52") || (productID === "57") ?
                                                            <div className="membershipDetails-tab">
                                                                <div className='mD-header'>
                                                                    <p> Platinum Membership Level Benefits:</p>
                                                                </div>
                                                                <div className='mD-body'>
                                                                    <ul className="referralTxt-lists">
                                                                        <li>All Platinum Membership Level Benefits.</li>
                                                                        <li>All Gold Membership Level Benefits.</li>
                                                                        <li>Purchase unlimited supplements at $15 per bottle.</li>
                                                                        <li>Purchase unlimited skincare products at $20 per item</li>
                                                                        <li>Purchase unlimited powder products at $25 per item</li>
                                                                        <li>Bonus Item: Better Health Overnight Newsletter.</li>
                                                                        <li>Bonus Item: The Celebrity Diet Cookbook.</li>
                                                                        <li>Bonus Item: Look Younger, Feel Younger Guide.</li>
                                                                        <li>Bonus offer: you will receive 5 free months of membership when 5 Successful Referrals sign up using your referral code.</li>
                                                                        <li>Bonus offer: for every 5 bottles purchased, get 1 free.</li>
                                                                        <li>The Platinum Membership will be automatically charged $21.00 every month thereafter for a period of 30 days from <br /> date of purchase. You can cancel anytime by calling <a href="tel:(480)573-0829">(480)573-0829</a> or by emailing<br /> <a href="mailto:support@wholesalehealthclub.com">support@wholesalehealthclub.com</a>.</li>
                                                                    </ul>
                                                                </div>
                                                            </div>
                                                            : ""}
                                                        {/* Silver Membership Level Benefits: */}
                                                        {(productID === "59") || (productID === "50") ?
                                                            <div className="membershipDetails-tab">
                                                                <div className='mD-header'>
                                                                    <p>Silver Membership Level Benefits:</p>
                                                                </div>
                                                                <div className='mD-body'>
                                                                    <ul className="referralTxt-lists">
                                                                        <li>Access to basic plus</li>
                                                                        <li>Purchase unlimited supplements at $15 per bottle.</li>
                                                                        <li>Purchase unlimited skincare products at $20 per item</li>
                                                                        {/* <li>Purchase unlimited powder products at $25 per item</li> */}
                                                                        <li>Bonus offer: you will receive 1 free month of membership when 1 Successful Referral sign up using your referral code.</li>
                                                                        <li>Bonus ebooks</li>
                                                                        <li>The Silver Membership will be automatically charged $10.00 every month thereafter for a period of 30 days from the
                                                                            date of purchase. You can cancel anytime by calling <a href="tel:(480)573-0829">(480)573-0829</a> or by emailing <a href="mailto:support@wholesalehealthclub.com">support@wholesalehealthclub.com</a>.</li>
                                                                    </ul>
                                                                </div>
                                                            </div>
                                                            : ""}
                                                        {/* Gold Membership Level Benefits: */}
                                                        {(productID === "58") || (productID === "51") ?
                                                            <div className="membershipDetails-tab">
                                                                <div className='mD-header'>
                                                                    <p>Gold Membership Level Benefits:</p>
                                                                </div>
                                                                <div className='mD-body'>
                                                                    <ul className="referralTxt-lists">
                                                                        <li>Access to standard plus</li>
                                                                        <li>Purchase unlimited supplements at $15 per bottle.</li>
                                                                        <li>Purchase unlimited skincare products at $20 per item</li>
                                                                        {/* <li>Purchase unlimited powder products at $25 per item</li> */}
                                                                        <li>Bonus offer: you will receive 1 free month of membership when 1 Successful Referral sign up using your referral code.</li>
                                                                        <li>Bonus offer: for every 5 bottles purchased, get 1 free.</li>
                                                                        <li>Bonus ebooks</li>
                                                                        <li>The Gold Membership will be automatically charged $17.00 every month thereafter for a period of 30 days from <br /> date of purchase. You can cancel anytime by calling <a href="tel:(480)573-0829">(480)573-0829</a> or by emailing <a href="mailto:support@wholesalehealthclub.com">support@wholesalehealthclub.com</a>.</li>
                                                                    </ul>
                                                                </div>
                                                            </div>
                                                            : ""}
                                                        {/* Basic  */}
                                                        {(productID === "60") || (productID === "66") ?
                                                            <div className="membershipDetails-tab">
                                                                <div className='mD-header'>
                                                                    <p>Basic Membership Level Benefits:</p>
                                                                </div>
                                                                <div className='mD-body'>
                                                                    <ul className="referralTxt-lists">
                                                                        <li>Access to member site</li>
                                                                        <li>Purchase unlimited supplements at $15 per bottle.</li>
                                                                        <li>Purchase unlimited skincare products at $20 per item</li>
                                                                        {/* <li>Purchase unlimited powder products at $25 per item</li> */}
                                                                        <li>The Basic Membership will be automatically charged $5.89 every month thereafter for a period of 30 days from the
                                                                            date of purchase. You can cancel anytime by calling <a href="tel:(480)573-0829">(480)573-0829</a> or by emailing&nbsp;
                                                                            <a href="mailto:support@wholesalehealthclub.com">support@wholesalehealthclub.com</a>.</li>
                                                                    </ul>
                                                                </div>
                                                            </div>
                                                            : ""}
                                                    </div>
                                                </>
                                                : <div>No active membership plan found</div>}
                                            </div>
                                        </Tab>
                                        <Tab eventKey="OrderHistory" title="Order History">
                                            <div className='infoCard-body'>
                                                <div className='orderHistory-table'>
                                                    <div className='table-responsive'>
                                                        <DataTable
                                                            noDataComponent="No data found" //or your component
                                                            columns={columns1}
                                                            data={searchOrder}
                                                            progressPending={pendingOrderData}
                                                            pagination
                                                            fixedHeader
                                                            subHeader
                                                            subHeaderComponent={
                                                                <input type="text" className={(orderList.length)? 'form-control serachInput ':'d-none'} placeholder="Search By Order Id"  onChange={(e) => { updateOrderHistoryData(e.target.value) }} />
                                                            }
                                                            subHeaderAlign="right"
                                                            defaultSortFieldId="oderId"
                                                            defaultSortAsc={false}
                                                        />
                                                    </div>
                                                </div>
                                            </div>
                                        </Tab>
                                        <Tab eventKey="Change-Password" title="Change Password">
                                            <div className='infoCard-body'>
                                                <Formik

                                                    initialValues={{ current_member_password: '', new_member_password: '', confirmPassword: '' }}
                                                    validationSchema={Yup.object({
                                                        current_member_password: Yup.string()
                                                            .required('Current password is required')
                                                            .min(8, 'Password must be at least 8 characters')
                                                            .max(40, 'Password must not exceed 40 characters'),
                                                        new_member_password: Yup.string()
                                                            .required('New Password is required')
                                                            .min(8, 'Password must be at least 8 characters')
                                                            .max(40, 'Password must not exceed 40 characters'),
                                                        confirmPassword: Yup.string()
                                                            .required('Confirm password is required')
                                                            .oneOf([Yup.ref('new_member_password'), null], 'Confirm password does not match'),

                                                    })}
                                                    onSubmit={(values, { setSubmitting }) => {
                                                        setSubmitting(true);
                                                        // alert(JSON.stringify(values, null, 2));
                                                        changePasswordSubmit(values);

                                                    }}
                                                >
                                                    <Form className=''>

                                                        <p className="titleBlock">CHANGE PASSWORD</p>
                                                        <div className='formDiv-cus'>
                                                            <div className='row formDiv-row'>
                                                                <div className='col-md-6 formDiv-col'>
                                                                    <label>Enter Your Current Password</label>
                                                                </div>
                                                                <div className='col-md-6 formDiv-col'>
                                                                    <Field className='form-control' type="password" name="current_member_password" />
                                                                    <CustomErrorMsg name="current_member_password" />
                                                                </div>

                                                            </div>
                                                            <div className='row formDiv-row'>
                                                                <div className='col-md-6 formDiv-col'>
                                                                    <label>Enter Your New Password</label>
                                                                </div>
                                                                <div className='col-md-6 formDiv-col'>
                                                                    <Field className='form-control' type="password" name="new_member_password" />
                                                                    <CustomErrorMsg name="new_member_password" />
                                                                </div>


                                                            </div>
                                                            <div className='row formDiv-row'>
                                                                <div className='col-md-6 formDiv-col'>
                                                                    <label>Confirm Password</label>
                                                                </div>
                                                                <div className='col-md-6 formDiv-col'>
                                                                    <Field className='form-control' type="password" name="confirmPassword" />
                                                                    <CustomErrorMsg name="confirmPassword" />
                                                                </div>

                                                            </div>

                                                            <div className='actionBtn text-right'>
                                                                <button type="submit" className='btn btn_update_mid'>Update</button>
                                                                {/* <button className='btn btn_cancle_mid'>Cancel</button> */}
                                                            </div>
                                                        </div>
                                                    </Form>
                                                </Formik>
                                            </div>
                                        </Tab>
                                    </Tabs>
                                </div>
                            </div>
                         </div>
                        {/* Left Col End  */}
                    </div>
                    {/* Row Ends  */}
                </div>




                

            </div>
            {/* My Account Page End  */}



            <Modal className='orderHistory' show={showModal} onHide={handleClose}>
                <Modal.Header closeButton>
                    <Modal.Title>Order History</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div className='orderHistory-table'>
                        <div className='table-responsive'>
                            <Table striped hover>
                                <thead>
                                    <tr>
                                        <th>Item #</th>
                                        <th>Quantity</th>
                                        <th>Price</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {productsList.map((item) => (
                                        <tr>
                                            <td>{item.name}</td>
                                            <td>{item.product_qty}</td>
                                            <td>${item.price}</td>
                                        </tr>
                                    ))}
                                </tbody>
                            </Table>
                        </div>
                    </div>
                </Modal.Body>
            </Modal>



        </>
    )

}


export default MyAccountInfo2


