import React, { useState, useEffect } from "react";
import axios from 'axios';
import Swal from 'sweetalert2'
import { Link, useNavigate, useLocation }  from 'react-router-dom';
import '../../assests/Lander/assets/css/dickPillUpsell.css';
import Copyright from "../Copyright";
import useStore from '../../store';
import AccessDeniedPage from "../AccessDeniedPage";

import tag from '../../assests/Lander/assets/images/d-pill/tag.png';
import bar from '../../assests/Lander/assets/images/d-pill/bar.png';
import badge from '../../assests/Lander/assets/images/d-pill/badge.png';
import dickPillUpsell1 from '../../assests/Lander/assets/images/d-pill/upsell1_img_new.png';
import bodyPic from '../../assests/Lander/assets/images/d-pill/pic1_boy.png';
import pic2 from '../../assests/Lander/assets/images/d-pill/pic2.png';
import close from '../../assests/Lander/assets/images/d-pill/close.png';
import star from '../../assests/Lander/assets/images/d-pill/star.png';

function DickPillUpsell1(){
    const [loadershow, setloadershow] = useState(false);
    const navigate = useNavigate();
    const location = useLocation();
    let params = location.search;
    const currentYear = new Date().getFullYear();
    let userDetails = useStore((state) => state.userDetails);
    const isLogin = useStore((state) => state.isLogin);
    const [isUpdateButtonClicked, setIsUpdateButtonClicked] = useState(false);

    function upsellProcced(){
        if (!isUpdateButtonClicked) {
            setloadershow(true);
            setIsUpdateButtonClicked(true);
            const formdata = new FormData();
            formdata.append('first_name', localStorage.getItem('firstName'));
            formdata.append('last_name', localStorage.getItem('lastName'));
            formdata.append('email', localStorage.getItem('email'));
            formdata.append('is_membership', 0);
            if(localStorage.getItem('c_s') === 'gethard1p-checkout'){
                formdata.append('product_id', 219);
            } else if(localStorage.getItem('c_s') === 'get1hardpp-checkout'){
                formdata.append('product_id', 253);
            } else if(localStorage.getItem('c_s') === 'alpha-checkout'){
                formdata.append('product_id', 269);
            } else if(localStorage.getItem('c_s') === 'DTC-alpha'){
                formdata.append('product_id', 311);
            }
            formdata.append('is_upsell', 1);
            formdata.append('order_id', localStorage.getItem('orderId'));
            formdata.append('affid', localStorage.getItem('affid'));
            formdata.append('sid', localStorage.getItem('sid'));
            formdata.append('s1', localStorage.getItem('s1'));
            formdata.append('s2', localStorage.getItem('s2'));
            formdata.append('s3', localStorage.getItem('s3'));
            formdata.append('click_id', localStorage.getItem('click_id'));
            formdata.append('notes', localStorage.getItem('notes'));
            formdata.append('user_id',userDetails.user_id);
            formdata.append('method',localStorage.getItem('method'));
            if(localStorage.getItem('funnel')){
                formdata.append('funnel',localStorage.getItem('funnel'));
            }
            if(localStorage.getItem('orderId')){
                axios.post(`api/place-upsell-order`, formdata).then(res => {
                    if (res.data.status === true) {
                        //setIsUpdateButtonClicked(false);
                        if(localStorage.getItem('up2_s') === 'healthy-living-product-total-men-health'){
                            localStorage.setItem('Upsell2_orderId', res.data.data.order_id);
                            if(localStorage.getItem('method') === 'paypal'){
                                window.location.href = '/'+localStorage.getItem('con_s')+params;
                            } else {
                                if(localStorage.getItem('up3_s')){
                                    window.location.href = '/'+localStorage.getItem('up3_s')+params;
                                } else {
                                    window.location.href = '/'+localStorage.getItem('con_s')+params;
                                }
                            }
                            
                        } else {
                            localStorage.setItem('Upsell1_orderId', res.data.data.order_id);
                            if(localStorage.getItem('method') === 'paypal'){
                                window.location.href = '/'+localStorage.getItem('con_s')+params;
                            } else {
                                if(localStorage.getItem('up2_s')){
                                    window.location.href = '/'+localStorage.getItem('up2_s')+params;
                                }
                                else{
                                    window.location.href = '/'+localStorage.getItem('con_s')+params;
                                }
                            }
                        }
                        
                    }
                    else{
                        setloadershow(false);
                        Swal.fire({
                            title: res.data.data.decline_reason,
                            icon: 'warning',
                            showCancelButton: false,
                            confirmButtonColor: '#3085d6',
                            cancelButtonColor: '#d33'
                        })
                    }
                })
            } else {
                Swal.fire({
                    title: 'Order Decline',
                    icon: 'warning',
                    showCancelButton: false,
                    confirmButtonColor: '#3085d6',
                    cancelButtonColor: '#d33'
                }).then((result) => {
                    /* Read more about isConfirmed, isDenied below */
                    if (result.isConfirmed) {
                        window.location.href = '/dashboard';
                    }
                })
            }
        }
    }

    function goToConfirmation(){
        setloadershow(true);
        if(localStorage.getItem('up2_s') === 'healthy-living-product-total-men-health'){
            if(localStorage.getItem('method') == 'paypal'){
                window.location.href = '/'+localStorage.getItem('con_s')+params;
            } else {
                if(localStorage.getItem('up3_s')){
                    window.location.href = '/'+localStorage.getItem('up3_s')+params;
                } else {
                    window.location.href = '/'+localStorage.getItem('con_s')+params;
                }
            }
            
        } else {
            if(localStorage.getItem('up2_s')){
                window.location.href = '/'+localStorage.getItem('up2_s')+params;
            }
            else{
                window.location.href = '/'+localStorage.getItem('con_s')+params;
            }
        }
    }

    return(
        <>
            { isLogin === 1 ?
            <>
            <div className={loadershow ? 'loading' : 'd-none'}></div>
            <div className="dpillUpsell">
                <div className="header_upsell">
                    <div className="upsellContainer text-center">
                        <div className="offer_tag"><img src={tag} alt="" /></div>
                        <span><img src={bar} alt="" /></span>
                    </div>
                </div>
                <div className="dPillUpsellBody text-center">
                    <div className="upsellContainer">
                        <h5>This Offer Expires Today!</h5>
                        <h1>Wait, your order is not complete…</h1>
                        <p className='subTitle'>Customers that purchase <em>ALPHA DRIVE MEN ENHANCEMENT GUMMIES</em> and also bought <em>TOTAL MEN'S HEALTH GUMMIES</em> achieve faster results!<br />
                        This <em>UNIQUE FORMULA</em> helps increase your <em>STAMINA</em> so you can <br /><em>LAST FOR HOURS</em> !  Lock in these discounted prices today!</p>
                        <div className="dPillUpsellBox">
                            <div className="product_wrap clearfix">
                                <div className="product_left">
                                    {/* <span className="badge"><img src={badge} alt="" /></span> */}
                                    <div className="product_pic">
                                        <span className="badge"><img src={badge} alt="" /></span>
                                        <img src={dickPillUpsell1} alt="" />
                                    </div>
                                </div>
                                <div className="product_content">
                                    <h2>OUR #1 BEST SELLING</h2>
                                    <div className="price_tagD">
                                        <span className="pic1"><img src={bodyPic} alt="" /></span>
                                        <div className="pricepic">
                                            REG
                                            <span>$129.95</span>
                                        </div>
                                    </div>
                                    <div className="big_text">
                                    INCREASED STAMINA <span>AND STAYING POWER </span>          
                                    </div>                                
                                    <div className="sub_text">JUMPSTART YOUR LIBIDO</div>
                                    <p> Get Harder for Longer! Increase your Stamina and Staying Power with Nitric Oxide Gummies! </p>
                                    <div className="price_area">
                                    Claim this One Time Deal for ONLY <span>$29.47<span className="title-block__retail">/bottle</span></span>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="dPillUpsellBottom">
                            <div className="only_text">ONLY 22 LEFT!</div>
                            <form  className="is-upsell">
                                <div className="text-center"><Link to="#" className="checkout_btn pulse" onClick={upsellProcced}>Complete Checkout!</Link></div>
                            </form>   
                            <div className="pic2"><img src={pic2} alt="" /></div>
                            <br />
                            <div className="dont_text">
                                <i><img src={close} alt="" /></i>
                                <span><Link to="#" onClick={goToConfirmation}>NO, I DON’T WANT TO TURBO CHARGE MY RESULTS</Link></span>
                            </div>
                        </div>

                    </div>
                </div>
                <div className="dpillComnt_area">
                    <div className="upsellContainer">
                        <div className="connection_text">
                        </div>
                        <div className="star_blog">
                            <i><img src={star} alt="" /></i>
                            <h4>Way Harder!</h4>
                            <span>by James</span>
                            <p>This product is way more enjoyable then forcing down a liquid option. Great flavor and great results! Very healthy dose that I can take either in the morning, during the day or at night. Highly recommend!</p>
                        </div>
                        <div className="star_blog">
                            <i><img src={star} alt="" /></i>
                            <h4>It works.</h4>
                            <span>by Carlos</span>
                            <p>These are some of the best tasting gummies out there ! Excellent flavor matched with high quality ingredients that yield amazing results. Definitely my go to gummies for improving natural testosterone. Highly recommended ! 10/10</p>
                        </div>
                        <div className="star_blog">
                            <i><img src={star} alt="" /></i>
                            <h4>Ordered for my husband</h4>
                            <span>by Samantha</span>
                            <p>Great product got this along with other supplements and each one is wonderfully made.</p>
                        </div>
                        <div className="star_blog">
                            <i><img src={star} alt="" /></i>
                            <h4>Amazing!</h4>
                            <span>by Damon</span>
                            <p>Love the product, no negative besides the gummy sticking to teeth. Overall, it is a gummy so that’s to be expected!
                        </p></div>
                    </div>
                    </div>
            </div>
            <Copyright/>
            </>
            : <AccessDeniedPage/> }
        </>
    )
}


export default DickPillUpsell1;